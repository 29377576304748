import Sortable from "sortablejs";

$(document).on("turbo:load", function () {
  document.querySelectorAll(".sortable").forEach((sortable) => {
    const $sortable = $(sortable);

    new Sortable(sortable, {
      group: $sortable.is(".nested") ? "nested" : undefined,
      handle: ".draggable",
      direction: $sortable.is(".vertical") ? "vertical" : "horizontal",
      animation: 100,
      invertSwap: $sortable.is(".nested"),
      onMove: function (evt) {
        const itemIsParent = evt.dragged.dataset.type === "parent";
        const groupIsParent = evt.to.dataset.type === "parent";

        // Prevent dragging a parent into a parent
        return itemIsParent && groupIsParent ? false : true;
      }
    });
  });
});
